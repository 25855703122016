import styled from '../../styles/styled-components';

export interface StyledProps {
  size?: 'wide' | 'medium';
}

const SectionContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  max-width: ${(props: StyledProps) =>
    props.size === 'wide' ? '980px' : '640px'};
  margin-left: auto;
  margin-right: auto;
`;

export default SectionContainer;
