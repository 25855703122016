import { Link } from 'gatsby';
import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from 'react-icons/fa';
import 'typeface-muli';
import styled from '../styles/styled-components';

const FooterStyled = styled.div`
  padding: 32px 20px 16px;
  background-color: ${props => props.theme.primary800};
  text-align: center;
  color: #fff;
`;
const SocialNetworks = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;
const SocialNetwork = styled.a.attrs({
  target: '_blank',
})`
  font-size: 20px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease;
  &:hover {
    color: #fff;
    background-color: #fff;
    color: ${p => p.theme.primary800};
  }
`;
const Text = styled.div`
  font-family: Muli;
  font-size: 14px;
  margin: 36px 0;
  font-weight: 600;
`;
const LinkStyled = styled(Link)`
  margin-left: 8px;
  margin-right: 8px;
  color: #fff;
  transition: color 0.25s ease;
  &:hover {
    color: ${p => p.theme.gray200};
  }
`;

const Footer: React.FC<{}> = () => (
  <FooterStyled>
    <SocialNetworks>
      <SocialNetwork href="https://www.facebook.com/Enbanca-383676332479013/">
        <FaFacebookF />
      </SocialNetwork>
      <SocialNetwork href="https://www.linkedin.com/company/enbanca">
        <FaLinkedinIn />
      </SocialNetwork>
      <SocialNetwork href="https://www.instagram.com/enbanca/">
        <FaInstagram />
      </SocialNetwork>
    </SocialNetworks>
    <div>
      <LinkStyled to="/faq">Preguntas frecuentas</LinkStyled>
      <LinkStyled to="/terminos-y-condiciones">
        Términos y condiciones
      </LinkStyled>
      <LinkStyled to="/politicas-de-privacidad">
        Política de privacidad
      </LinkStyled>
      <LinkStyled to="/autorizaciones-y-declaraciones">
        Autorizaciones y declaraciones
      </LinkStyled>
    </div>
    <Text>© 2019 enbanca</Text>
  </FooterStyled>
);

export default Footer;
