const getAuthToken = () => {
  let token: string;
  if (localStorage) {
    token = localStorage.getItem('token') || '';
  } else {
    token = document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
  }
  return token.trim();
};

export default getAuthToken;
