import React from 'react';
import styled from '../../styles/styled-components';

const SelectContainer = styled.div`
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    border: 0.375rem solid transparent;
    border-top: 0.5rem solid ${p => p.theme.gray300};
    pointer-events: none;
  }
  select {
    display: block;
    width: 100%;
    appearance: none;
    padding: 0.5rem;
    padding-right: 2rem;
    border: 1px solid ${p => p.theme.gray300};
    border-radius: ${p => p.theme.borderRadius};
  }
`;

const Select = ({
  ...props
}: React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>) => {
  return (
    <SelectContainer>
      <select {...props} />
    </SelectContainer>
  );
};

export default Select;
