import None from '../types/None';
import serviceBuilder from '../types/serviceBuilder';

export interface Product {
  id: number;
  name: string;
  merchantName: string;
  getUserInfo: boolean;
  merchantId: number;
  productType: string;
  subProductType: string;
  description: string;
  rateFrom: number;
  rateTo: number;
  amountTo: number;
  associatedCosts: string;
  feeType: string;
  minRequirements: string;
  minIncomes: string;
  imageUrl: string;
  requestLink: string;
}
export interface Result {
  payload: Product[];
}
export const url = 'products/public/all';

export default serviceBuilder<None, Result>('get', {
  url,
  auth: false,
});
