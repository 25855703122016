import * as qs from 'query-string';
import React, { useState } from 'react';
import styled from 'styled-components';
import feeTypes from '../../data/feeTypes';
import slugify from '../../helpers/slugify';
import { Product } from '../../services/productsGet';
import Button from './Button';
import Card from './Card';

const breakpoint = '540px';

interface StyledProps {
  isOpen: boolean;
}

const ProductCard = styled(Card)`
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
`;
const Summary = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoint}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
const Head = styled.div`
  @media (min-width: ${breakpoint}) {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
  }
`;
const Title = styled.h3`
  color: ${p => p.theme.primary300};
  margin-bottom: 0.25rem;
  text-align: center;
  @media (min-width: ${breakpoint}) {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
`;
const Subtitle = styled.h4`
  font-weight: 300;
  font-size: 1.125rem;
  text-align: center;
`;
const Group = styled.p`
  font-weight: 300;
  text-align: center;
  @media (min-width: ${breakpoint}) {
    text-align: left;
  }
`;
const Image = styled.img`
  max-width: 10rem;
  max-height: 6rem;
  height: auto;
  margin-bottom: 1rem;
  align-self: center;
`;
const Description = styled.div`
  margin-bottom: 1rem;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 1rem;
  border-top: 1px solid ${p => p.theme.gray300};
  @media (min-width: ${breakpoint}) {
    justify-content: flex-end;
    & > * {
      margin-left: 1rem;
    }
  }

  .modal-contenido {
    background-color: #ffff;
    width: 380px;
    padding: 10px 20px;
    margin: 20% auto;
    position: relative;
    text-align: Center;
    border-radius: 9px;
  }
  .modal {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    margin-left: -1rem;
    pointer-events: none;
    transition: all 1s;
    .item-end {
      margin-left: 100%;
    }
  }
  #information:target {
    opacity: 1;
    pointer-events: auto;
  }
`;
const MoreInfoButton = styled.span`
  color: ${p => p.theme.primary300};
  position: relative;
  margin-right: 1rem;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    right: -0.75rem;
    top: 0.5rem;
    width: 0;
    height: 0;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    border-top: 0.375rem solid ${p => p.theme.primary300};
    transition: transform 0.25s ease;
    transform: ${(p: StyledProps) =>
    p.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`;

const MoreInfo = styled.div`
  margin-top: 1rem;
  & > *:last-child {
    margin-bottom: 0;
  }
`;
const MoreInfoBlock = styled.div`
  margin-bottom: 1.5rem;
`;
const MoreInfoText = styled.div`
  white-space: pre-line;
`;

const getName = (dictionary: { [key: string]: string }) => (name: string) =>
  dictionary[name] || name;
const feeTypeName = getName(feeTypes);
const productTypeName = getName({
  creditos: 'Crédito',
  ahorro: 'Ahorro',
  seguros: 'Seguro',
});

interface Props {
  data: Product;
}

const URL_IMAGES = 'http://merchants.enbanca.co/images/';

const ProductItem = ({ data }: Props) => {
  const [moreInfo, setMoreInfo] = useState(false);

  const {
    id,
    merchantId,
    merchantName,
    productType,
    subProductType,
    name,
    description,
    imageUrl,
    requestLink,
    minRequirements,
    feeType,
    associatedCosts,
    getUserInfo,
  } = data;

  const utm = {
    utm_source: 'enbanca',
    utm_medium: 'web',
    utm_campaign: slugify(name),
  };
  const requestLinkUTM = requestLink
    ? `${requestLink}?${qs.stringify(utm)}`
    : '';

  const hasMoreInfo = !!minRequirements || !!feeType || !!associatedCosts;

  const handleProductClick = () => {
    (window as any).KeenClient &&
      (window as any).KeenClient.recordEvent('product', {
        product: {
          merchantName,
          merchantId,
          productName: name,
          productId: id,
          requestLink,
          productType,
          subProductType,
        },
      });
  };

  return (
    <ProductCard>
      <Summary>
        <div>
          <Head>
            <Title>{name}</Title>
            <Subtitle>{merchantName}</Subtitle>
          </Head>
          <Group>{name}</Group>
        </div>
        <Image src={URL_IMAGES + imageUrl} alt={name} />
      </Summary>

      <Description>{description}</Description>

      <Actions>
        {hasMoreInfo && (
          <MoreInfoButton
            isOpen={moreInfo}
            onClick={() => setMoreInfo(prevMoreInfo => !prevMoreInfo)}
          >
            Más información
          </MoreInfoButton>
        )}
        {!!requestLinkUTM &&
          ((getUserInfo === true && (
            <>
              <Button href="#information"> Solicitar </Button>

              <div id="information" className="modal">
                <div className="modal-contenido">
                  <a className="item-end" href="#">
                    x
                  </a>

                  <p>
                    Estoy de acuerdo en ser contactado por la entidad <b>{merchantName} </b>para continuar con la solicitud del crédito.
                  </p>
                  <Button
                    target="_blank"
                    href={`https://app.enbanca.co/registro/?ide=${id}`}
                  >
                    Registro
                  </Button>
                </div>
              </div>
            </>
          )) || (
              <Button
                href={requestLinkUTM}
                target="_blank"
                onClick={handleProductClick}
              >
                Solicitar
              </Button>
            ))}
      </Actions>

      {moreInfo && (
        <MoreInfo>
          {!!minRequirements && (
            <MoreInfoBlock>
              <h5>Requisitos mínimos</h5>
              <MoreInfoText>{minRequirements}</MoreInfoText>
            </MoreInfoBlock>
          )}
          {!!associatedCosts && (
            <MoreInfoBlock>
              <h5>Costos asociados</h5>
              <MoreInfoText>{associatedCosts}</MoreInfoText>
            </MoreInfoBlock>
          )}
          {!!feeType && (
            <MoreInfoBlock>
              <h5>Tipo de cuota</h5>
              <MoreInfoText>{feeTypeName(feeType)}</MoreInfoText>
            </MoreInfoBlock>
          )}
        </MoreInfo>
      )}
    </ProductCard>
  );
};

export default ProductItem;
