import React from 'react';
import styled from '../../styles/styled-components';

interface Props {
  name: string;
  label: string;
  children: React.ReactNode;
}

const FieldStyled = styled.div`
  margin-bottom: 1rem;
`;
const LabelText = styled.div`
  margin-bottom: 0.25rem;
`;

const Field = ({ name, label, children, ...rest }: Props) => {
  return (
    <FieldStyled {...rest}>
      <label htmlFor={name}>
        <LabelText>{label}</LabelText>
        {children}
      </label>
    </FieldStyled>
  );
};

export default Field;
