import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { Merchant } from '../services/merchantsGet';
import { ProductType } from '../services/productsSubtypeGet';
import Field from './Forms/Field';
import Select from './Forms/Select';
import Card from './UI/Card';

const CardStyled = styled(Card)`
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  & > div:last-child {
    margin-bottom: 0;
  }
`;

const style = {
  display: 'none',
};

export interface FilterValues {
  productType: string;
  subProductType: string;
  merchant: string;
}

interface Props {
  merchants: Merchant[];
  // productsSubtypes: ProductSubtypeResponse | null;
  productsType: ProductType[];
  onFilter(filterValues: FilterValues): void;
}

const initialState: FilterValues = {
  productType: '',
  subProductType: '',
  merchant: '',
};

const reducer = (
  currentState: FilterValues,
  action: { target: { name: string; value: string } }
) => {
  return {
    ...currentState,
    [action.target.name]: action.target.value,
  };
};

const ProductsFilter = ({
  merchants,
  // productsSubtypes,
  productsType,
  onFilter,
  ...restProps
}: Props) => {
  const [valuesState, valuesDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    valuesDispatch({ target: { name: 'subProductType', value: '' } });
  }, [valuesState.productType]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.persist();
    valuesDispatch(e);
  };

  useEffect(() => {
    onFilter(valuesState);
  }, [valuesState]);

  return (
    <CardStyled {...restProps}>
      {}
      <Field label="Tipo de producto" name="productType">
        <Select
          name="productType"
          id="productType"
          onChange={handleSelectChange}
          value={valuesState.productType}
        >
          <option value="">Todos</option>
          {productsType.map(item => (
            <option value={item.value} key={item.value}>
              {item.name}
            </option>
          ))}
        </Select>
      </Field>
      {productsType.filter(
        item =>
          item.value === valuesState.productType &&
          item.subProductsType.length > 0
      ).length > 0 && (
        <Field label="Descripción" name="subProductType">
          <Select
            name="subProductType"
            id="subProductType"
            onChange={handleSelectChange}
            value={valuesState.subProductType}
          >
            <option value="">Todos</option>
            {productsType.map(
              item =>
                item.value === valuesState.productType &&
                item.subProductsType.map(subType => (
                  <option value={subType.value} key={subType.value}>
                    {subType.label}
                  </option>
                ))
            )}
          </Select>
        </Field>
      )}
      <Field label="Entidad" name="merchant">
        <Select
          name="merchant"
          id="merchant"
          onChange={handleSelectChange}
          value={valuesState.merchant}
        >
          <option value="">Todos</option>
          {merchants.map(item =>
            item.disabled ? (
              <option value={item.value} key={item.value} style={style}>
                {item.label}
              </option>
            ) : (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            )
          )}
        </Select>
      </Field>
    </CardStyled>
  );
};

export default ProductsFilter;
