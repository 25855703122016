import 'typeface-roboto';
import normalize from './normalize';
import { createGlobalStyle } from './styled-components';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  html {
    scroll-behavior: smooth;
  }
  *, *:before, *:after {
    box-sizing: border-box;
  }
  body {
    font-family: Roboto, sans-serif;
    color: ${props => props.theme.gray900};
  }
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 32px;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.primary500};
    &:hover {
      color: ${props => props.theme.primary700};
    }
  }
  #netlify-identity-widget {
    z-index: 99999 !important;
  }
`;

export default GlobalStyle;
