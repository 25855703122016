import { graphql } from 'gatsby';
import React from 'react';
import showdown from 'showdown';
import styled from 'styled-components';
import Content, { HTMLContent } from '../components/Content';
import Layout from '../components/Layout';
import ProductsListContainer from '../components/ProductsListContainer';
import SEO from '../components/SEO';
import ContentHeader from '../components/UI/ContentHeader';
import SectionContainer from '../components/UI/SectionContainer';
import DataMarkdown from '../types/DataMarkdown';
import Image from '../types/Image';

const breakpoint = '860px';

const SectionContainerStyled = styled(SectionContainer)`
  @media (min-width: ${breakpoint}) {
    max-width: 1024px;
    margin: 0 auto;
  }
`;

const converter = new showdown.Converter();

interface PropsTemplate {
  title: string;
  subheading: string;
  image: Image | string;
  content: string;
  contentComponent?: Content | HTMLContent;
}

export const ProductsPageTemplate: React.FC<PropsTemplate> = ({
  title,
  subheading,
  image,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <ContentHeader
        title={title}
        subheading={subheading}
        image={
          typeof image === 'string' ? image : image.childImageSharp.fluid.src
        }
      />
      <SectionContainerStyled>
        <PageContent content={content} />
        <ProductsListContainer />
      </SectionContainerStyled>
    </>
  );
};

interface Props {
  html: string;
  frontmatter: {
    title: string;
    subheading: string;
    image: Image;
  };
}

const ProductsPage: React.FC<DataMarkdown<Props>> = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <ProductsPageTemplate
        title={post.frontmatter.title}
        subheading={post.frontmatter.subheading}
        image={post.frontmatter.image}
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

export default ProductsPage;

export const productsPageQuery = graphql`
  query ProductsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
