import { graphql, StaticQuery } from 'gatsby';
import React, { useState } from 'react';
import 'typeface-montserrat';
import 'typeface-muli';
import 'typeface-roboto';
import '../helpers/captureUTM';
import GlobalStyle from '../styles/GlobalStyle';
import styled, { css, ThemeProvider } from '../styles/styled-components';
import theme from '../styles/theme';
import Footer from './Footer';
import Header from './Header';
import MobileMenu from './MobileMenu';

interface StyledProps {
  openMenu: boolean;
}

const OpenMenu = css`
  height: 100vh;
  overflow: hidden;
  transform: scale(0.84) translateX(-93vw) translateZ(0);
`;
const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
  ${(props: StyledProps) => props.openMenu && OpenMenu};
  z-index: 300;
  position: relative;
`;
const Main = styled.main`
  flex: 1;
  position: relative;
  z-index: 300;
  background-color: #fff;
`;
const MobileMenuBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  background: linear-gradient(
    145deg,
    ${props => props.theme.primary200},
    ${props => props.theme.secondary500}
  ) !important;
`;

export interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <LayoutStyled openMenu={showMobileMenu}>
              <Header
                siteTitle={data.site.siteMetadata.title}
                onClick={() => setShowMobileMenu(true)}
              />
              <Main>{children}</Main>
              <Footer />
            </LayoutStyled>
            <MobileMenu
              isVisible={showMobileMenu}
              onClick={() => setShowMobileMenu(false)}
            />
            <MobileMenuBackground />
          </>
        </ThemeProvider>
      )}
    />
  );
};

export default Layout;
