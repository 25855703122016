import { Link } from 'gatsby';
import React from 'react';
import styled from '../styles/styled-components';

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;
const LinkStyled = styled(Link).attrs({
  activeClassName: 'active',
})``;

const Menu: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  ...restProps
}) => (
  <div {...restProps}>
    <List>
      <li>
        <a href="https://app.enbanca.co/dashboard">Mi historial</a>
      </li>
      <li>
        <LinkStyled to="/faq">Preguntas frecuentes</LinkStyled>
      </li>
      <li>
        <a href="/productos-financieros">Productos financieros</a>
      </li>
      <li>
        <LinkStyled to="/educacion">Educación financiera</LinkStyled>
      </li>
      <li>
        <a href="https://app.enbanca.co/contacto">Contacto</a>
      </li>
      <li>
        <a href="https://app.enbanca.co/registro" className="button solid">
          Ingreso
        </a>
      </li>
    </List>
  </div>
);

export default Menu;
