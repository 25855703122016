// import Merchant from '../types/Merchant';
// // import api from './api';
// // export const url = 'merchants/list';
// export const url = 'merchants/public/all';

// const merchantsGet = async (): Promise<Merchant[]> => {
//   // const result = await api.get(url);
//   // return result.payload;
//   return Promise.resolve(merchants);
// };

// export default merchantsGet;

import None from '../types/None';
import serviceBuilder from '../types/serviceBuilder';
export interface Merchant {
  value: number;
  label: string;
  disabled: boolean;
}

export interface Result {
  payload: Merchant[];
}

export const url = 'merchants/public/all';

export default serviceBuilder<None, Result>('get', {
  url,
  auth: false,
});
