import ThemeInterface from './themeDefinition';

const gray = {
  gray050: '#F7F7F7',
  gray100: '#E1E1E1',
  gray200: '#CFCFCF',
  gray300: '#B1B1B1',
  gray400: '#9E9E9E',
  gray500: '#7E7E7E',
  gray600: '#626262',
  gray700: '#515151',
  gray800: '#3B3B3B',
  gray900: '#222222',
};

const theme: ThemeInterface = {
  ...gray,

  primary200: '#0f8fff',
  primary300: '#1d89f9',
  primary400: '#3452ff',
  primary500: '#0a56d1',
  primary600: '#4563c5',
  primary700: '#003388',
  primary800: '#32396c',

  secondary500: '#0de2b8',
  secondary600: '#2ed397',

  accentA500: '#8224e3',
  accentB500: '#ff1053',
  accentB600: '#e50d0d',

  borderRadius: '4px',
};

export default theme;

// sizes: 4(0.25rem) 8(0.5rem) 12(0.75rem) 16(1rem) 24(1.5rem)
//        32(2rem) 48(3rem) 64(4rem) 96(6rem) 128(8rem) 192(12rem) 256(16rem)
//        384(24rem) 512(32rem) 640(40rem) 768(48rem)
// fonts sizes: 12 14 16 18 20 24 30 36 48 60 72
// used fonts sizes: 20 24 36
