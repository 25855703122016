import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useService from '../hooks/useService';
import merchantsGet, { Merchant } from '../services/merchantsGet';
import productsGet, { Product } from '../services/productsGet';
import productsSubtypeGet, {
  ProductType,
} from '../services/productsSubtypeGet';
import ProductsFilter, { FilterValues } from './ProductsFilter';
import Button from './UI/Button';
import Card from './UI/Card';
import ProductItem from './UI/ProductItem';

const breakpoint = '860px';

const ProductsListStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  @media (min-width: ${breakpoint}) {
    flex-direction: row;
  }
`;
const List = styled.div`
  flex: 2;
  @media (min-width: ${breakpoint}) {
    margin-left: 0.625rem;
  }
`;
const Sidebar = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoint}) {
    margin-right: 0.625rem;
    max-width: 16rem;
  }
`;
const AdvicesCard = styled(Card)`
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  @media (min-width: ${breakpoint}) {
    order: 2;
  }
  p {
    font-size: 1.25rem;
    font-weight: 300;
    margin-top: 0;
  }
`;
const ProductsFilterStyled = styled(ProductsFilter)`
  @media (min-width: ${breakpoint}) {
    order: 1;
  }
`;
const NotFound = styled.div`
  text-align: center;
`;

const ProductsListContainer: React.FC = () => {
  const [products, getProduct] = useService(productsGet);

  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [merchantsResult, getMerchants] = useService(merchantsGet);

  const [productsTypes, setProductstypes] = useState<ProductType[]>([]);
  const [productsTypeResult, getProductsType] = useService(productsSubtypeGet);

  useEffect(() => {
    (async () => {
      getProduct();
      getMerchants();
      getProductsType();
    })();
  }, []);

  const handleFilter = (values: FilterValues) => {
    products.status === 'loaded' &&
      setProducts(products.payload.payload, values);
    setProductType();
  };

  const reloadMerchants = (prdt: Product[]) => {
    if (merchantsResult.status === 'loaded') {
      const checkMerchants: Merchant[] = merchantsResult.payload.payload.map(
        merchant => {
          const filterProducts: Product[] = prdt.filter(product => {
            return Number(merchant.value) === product.merchantId;
          });
          merchant.disabled = filterProducts.length === 0;

          return merchant;
        }
      );
      setMerchants(checkMerchants);
    }
  };

  const setProducts = (prdt: Product[], values: FilterValues) => {
    const allProducts: Product[] = prdt.filter(
      product =>
        (!values.productType || product.productType === values.productType) &&
        (!values.subProductType ||
          product.subProductType === values.subProductType) &&
        (!values.merchant || product.merchantId.toString() === values.merchant)
    );

    values.merchant === '' && reloadMerchants(allProducts);
    setFilteredProducts(allProducts);
    return true;
  };

  const setProductType = () => {
    if (productsTypeResult.status === 'loaded') {
      const allProductsType = productsTypeResult.payload.payload.map(
        productType => productType
      );
      setProductstypes(allProductsType);
    }
  };

  return (
    <>
      {products.status === 'loaded' && (
        <ProductsListStyled>
          <Sidebar>
            <AdvicesCard>
              <p>
                Obtén recomendaciones de créditos de acuerdo con tu perfil y
                capacidad de pago
              </p>
              <Button href="https://app.enbanca.co/">Empieza ahora</Button>
            </AdvicesCard>
            <ProductsFilterStyled
              merchants={merchants}
              productsType={productsTypes}
              // productsSubtypes={productsSubtypes}
              onFilter={handleFilter}
            />
          </Sidebar>
          <List>
            {!filteredProducts.length && (
              <NotFound>
                No tenemos productos con el filtro que usaste.
              </NotFound>
            )}
            {filteredProducts.map(product => (
              <ProductItem data={product} key={product.id} />
            ))}
          </List>
        </ProductsListStyled>
      )}
    </>
  );
};

export default ProductsListContainer;
