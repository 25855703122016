import getAuthToken from '../helpers/getAuthToken';
import ApiError from '../types/ApiError';

// const API_URL =
//   process.env.REACT_APP_API_HOST + '/' + process.env.REACT_APP_API_PATH;

const API_URL = 'https://merchants.enbanca.co/api';

// const API_URL: string =
//   process.env.REACT_APP_API_HOST === 'auto'
//     ? `${location.origin.replace(/\/$/g, '')}${process.env.REACT_APP_API_PATH}`
//     : `${process.env.REACT_APP_API_HOST!.replace(/\/$/g, '')}${
//         process.env.REACT_APP_API_PATH
//       }`;

export type Method = 'get' | 'post' | 'put';

export const httpMethod = (method: Method, apiUrl: string) => async (
  endpoint: string,
  authentication?: boolean,
  body?: Record<string | number, any>,
  queryParams?: Record<string | number, any>
) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json; charset=utf-8');

  if (authentication) {
    const token = getAuthToken();
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    } else {
      throw new Error('Missing token');
    }
  }

  if (method === 'get' && body) {
    throw new Error('The GET method can not have body');
  }

  const options: RequestInit = {
    method: method.toUpperCase(),
    // mode: 'no-cors',
    cache: 'no-cache',
    headers,
    body: body && JSON.stringify(body),
  };

  const endpointParsed = Object.entries(queryParams || []).reduce(
    (url, [key, value]) => url.replace(`:${key}`, value),
    endpoint
  );

  const response = await fetch(`${apiUrl}/${endpointParsed}`, options);

  if (response.ok) {
    try {
      return await response.json();
    } catch {
      return {};
    }
  } else {
    const responseData = await response.json();
    throw new ApiError(response.statusText, response.status, responseData);
  }
};

export const get = httpMethod('get', API_URL);
export const post = httpMethod('post', API_URL);
export const put = httpMethod('put', API_URL);
