if (typeof window !== 'undefined' && window) {
  const queryParams = window.location.search
    .replace('?', '')
    .split('&')
    .reduce((accum: Record<string, string>, item: string) => {
      const keyValue = item.split('=');
      if (keyValue[0].toLowerCase().startsWith('utm')) {
        accum[keyValue[0]] = keyValue[1];
      }
      return accum;
    }, {});

  const expires = new Date();
  expires.setDate(expires.getDate() + 90);

  document.cookie = `ebutm=${JSON.stringify(
    queryParams
  )}; domain=enbanca.co; expires=${expires.toUTCString()};`;
}

export default undefined;
