class ApiError<T = any> extends Error {
  code: number;
  payload: T;

  constructor(message: string, code: number, response: any) {
    super(message);
    Object.setPrototypeOf(this, ApiError.prototype);
    this.code = code;
    this.payload = response;
  }
}

export default ApiError;
