import styled from 'styled-components';

const Card = styled.div`
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0;
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 1.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  padding: 1.25rem;
`;

export default Card;
