import styled from '../../styles/styled-components';

const Button = styled.a`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  text-decoration: none;
  display: inline-block;
  padding: 0.5rem 0.75rem;
  border-radius: ${props => props.theme.borderRadius};
  color: #fff;
  background: ${props => props.theme.primary300};
  position: relative;
  transition: all 0.25s ease;
  &:hover {
    color: #fff;
  }
`;

export default Button;
