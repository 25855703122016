// import productsSubtypes from '../data/productsSubtypes';
// import Option from '../types/Option';
// import ProductTypes from '../types/ProductTypes';
// // import api from './api';

// export type ProductSubtypeResponse = Record<ProductTypes, Option[]>;

// export const url = 'products/subtypes/list/';

// const productsSubtypeGet = async (): Promise<ProductSubtypeResponse> => {
//   // const result = await api.get(url);
//   // return result.payload;
//   return Promise.resolve(productsSubtypes);
// };

// export default productsSubtypeGet;

import None from '../types/None';
import serviceBuilder from '../types/serviceBuilder';

export interface SubProductType {
  value: string;
  label: string;
}
export interface ProductType {
  value: string;
  name: string;
  subProductsType: SubProductType[];
}

export interface Result {
  payload: ProductType[];
}

export const url = 'products/public/types';

export default serviceBuilder<None, Result>('get', {
  url,
  auth: false,
});
