import { SetStateAction } from 'react';
import { DispatchPromise } from '../hooks/useStatePromise';
import ApiError from '../types/ApiError';
import None from '../types/None';
import { Service, ServiceError, ServiceLoaded } from '../types/Service';
import { get, Method, post, put } from './api';

export interface ServiceMethod<Params = None, Result = None, Error = any> {
  (payload?: Params): Promise<ServiceLoaded<Result> | ServiceError<Error>>;
}

type Dispatch<Result, Error> =
  | DispatchPromise<Service<Result, Error>>
  | React.Dispatch<SetStateAction<Service<Result, Error>>>;

interface Options<Result, Error> {
  url: string;
  auth: boolean;
  set?: Dispatch<Result, Error>;
}

const serviceBuilder = <Params = None, Result = None, Error = any>(
  method: Method,
  { url, auth, set }: Options<Result, Error>
): ServiceMethod<Params, Result, Error> => {
  const request = {
    get: (params?: Params) => get(url, auth, undefined, params),
    post: (params?: Params) => post(url, auth, params),
    put: (params?: Params) => put(url, auth, params),
  }[method];

  return async (params?: Params) => {
    try {
      set && (await set({ status: 'loading' }));
      const result: Result = await request(params);

      const state: ServiceLoaded<Result> = {
        status: 'loaded',
        payload: result,
      };

      set && (await set(state));
      return state;
    } catch (e) {
      const error: ApiError<Error> = e;
      const state: ServiceError<Error> = {
        status: 'error',
        error,
        payload: error.payload,
      };

      set && (await set(state));
      return state;
    }
  };
};

export default serviceBuilder;
