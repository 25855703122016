import { Link } from 'gatsby';
import React from 'react';
import logo from '../img/logoenbanca.png';
import styled from '../styles/styled-components';
import Menu from './Menu';

const breakpoint = '810px';

const HeaderStyled = styled.div`
  background-color: #fff;
  position: relative;
  z-index: 200;
`;
const Container = styled.div`
  margin: 0 auto;
  padding: 12px 20px;
  max-width: 1080px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LinkLogo = styled(Link)`
  display: flex;
  flex-direction: column;
`;
const Logo = styled.img`
  height: 24px;
`;
const Slogan = styled.div`
  font-size: 0.75rem;
  font-weight: 300;
  margin-top: 0.25rem;
  color: ${p => p.theme.gray900};
`;
const MenuStyled = styled(Menu)`
  display: none;
  @media (min-width: ${breakpoint}) {
    display: block;
    ul {
      display: flex;
    }
    li {
      margin: 0 15px;
    }
    a {
      color: ${props => props.theme.gray900};
      text-decoration: none;
      font-weight: 400;
      &.active {
        color: ${props => props.theme.primary500};
      }
      &.button {
        padding: 8px 20px;
        border-radius: ${props => props.theme.borderRadius};
      }
      &.button.solid {
        color: #fff;
        border: 2px solid ${props => props.theme.primary500};
        background: ${props => props.theme.primary500};
      }
      &.button.outline {
        border: 2px solid ${props => props.theme.gray900};
      }
    }
  }
`;
const MenuButton = styled.div`
  cursor: pointer;
  @media (min-width: ${breakpoint}) {
    display: none;
  }
  i {
    display: block;
    height: 2px;
    width: 22px;
    background-color: #000;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
      width: 17px;
    }
  }
`;

export interface Props {
  siteTitle: string;
  onClick: () => void;
}

const Header: React.FC<Props> = ({ siteTitle, onClick }) => (
  <HeaderStyled>
    <Container>
      <LinkLogo to="/">
        <Logo src={logo} />
        <Slogan>El poder de tu información</Slogan>
      </LinkLogo>
      <MenuStyled />
      <MenuButton onClick={onClick}>
        <i />
        <i />
        <i />
      </MenuButton>
    </Container>
  </HeaderStyled>
);

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
