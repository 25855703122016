import React from 'react';
import styled from '../styles/styled-components';
import Menu from './Menu';

interface StyledProps {
  isVisible: boolean;
}

const MobileMenuStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 78vw;
  z-index: 200;
  padding: 130px 50px;
  transform: translateX(
    ${(props: StyledProps) => (props.isVisible ? 0 : 78)}vw
  );
  transition: transform 0.8s ease;
`;
const Close = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  &:before {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #000;
    opacity: 0.1;
  }
  i {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-right: -10px;
    &:before {
      content: '';
      position: absolute;
      background-color: #fff;
      width: 2px;
      height: 100%;
      top: 0px;
      right: 9px;
      transform: rotate(45deg);
    }
    &:after {
      content: '';
      position: absolute;
      background-color: #fff;
      width: 100%;
      height: 2px;
      top: 9px;
      right: 0;
      transform: rotate(45deg);
    }
  }
`;
const MenuStyled = styled(Menu)`
  display: flex;
  flex-direction: column;
  li {
    margin-bottom: 16px;
  }
  a {
    font-size: 30px;
    color: #fff;
    text-decoration: none;
    position: relative;
    &.active::after {
      content: ' ';
      position: absolute;
      border-top: 2px solid #fff;
      left: 0;
      bottom: -2px;
      width: 100%;
      display: inline-block;
    }
  }
`;

export interface Props {
  isVisible: boolean;
  onClick: () => void;
}

const MobileMenu: React.FC<Props> = ({ isVisible, onClick }) => (
  <MobileMenuStyled isVisible={isVisible}>
    <Close onClick={onClick}>
      <i />
    </Close>
    <MenuStyled />
  </MobileMenuStyled>
);

export default MobileMenu;
